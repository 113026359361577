import { useSelector, useDispatch } from 'react-redux';

import { transactionActions } from '_store';
import {useSearchParams} from "react-router-dom";
import { Column } from '@ant-design/plots';


export { Home };

function Home() {
    const dispatch = useDispatch();
    const {transactions} = useSelector(x => x.transactions);

    const [searchParams] = useSearchParams()

    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date")
    if (!transactions) {
        dispatch(transactionActions.getByRange({startDate, endDate}));
    }
    let data = []
    if (transactions && !transactions.loading) {

        const map = transactions.filter(t => t.amount > 0).reduce((map, t) => {
            const date = new Date(Date.parse(t.creation_time)).toLocaleDateString('en-US');
            const key = date + t.category;
            if(map[key]) {
                const amount = map[key].amount + t.amount
                map[key] = {...map[key], amount}
            } else {
                map[key] = {
                    date,
                    category:t.category,
                    amount:t.amount
                }
            }
            return map;
        }, {});
        data = Object.values(map).map(t=> ({
            ...t,
            amount: t.amount/100
        }))
    }

    const configSummary = {
        data,
        xField: 'date',
        yField: 'amount',
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
            rotate: true
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };

    const configDetail = {
        ...configSummary,
        isStack: true,
        seriesField: 'category',
    };

    return (
        <div>
            {transactions && transactions.length &&
                <div>
                    <ul>
                        <Column {...configSummary} />
                    </ul>
                    <ul>
                        <Column {...configDetail} />
                    </ul>
                </div>
            }
            {(!transactions || transactions.loading) && <div className="spinner-border spinner-border-sm"></div>}
            {transactions && transactions.error && <div className="text-danger">Error loading users: {transactions.error.message}</div>}
        </div>
    );
}
