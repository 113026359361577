import { useEffect } from 'react';

import {useDispatch, useSelector} from 'react-redux';

import { history } from '_helpers';
import { authActions } from '_store';
import {useParams, useSearchParams} from "react-router-dom";

export { LoginToken };

function LoginToken() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);

    useEffect(() => {
        // redirect to home if already logged in
        //if (authUser) history.navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {token} = useParams();
    dispatch(authActions.loginToken({token}))

    const [searchParams] = useSearchParams()

    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date")

   if (authUser) history.navigate(`/?start_date=${startDate}&end_date=${endDate}`);
    return (<></>)
}
